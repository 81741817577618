import React from 'react'
import useScrollVisibility from 'hooks/useScrollVisibility'

function OurSpace({ data }) {
  const [isVisible, sectionRef] = useScrollVisibility('our-space-section')

  return (
    <section ref={sectionRef} id='clinica' className='flex w-full flex-col items-center justify-center bg-light py-8'>
      <div
        className={`flex w-11/12 flex-col gap-8 overflow-hidden md:w-9/12 ${isVisible ? 'animate-scaleUp' : 'opacity-0'}`}
      >
        <header className={`flex flex-col items-center justify-end gap-2 text-center`}>
          <h2 className='text-gray-900 text-4xl font-semibold md:text-5xl'>Galeria da Clínica</h2>
          <p className='text-xl font-medium'>Conheça nosso espaço</p>
        </header>

        <div className='flex gap-4 overflow-x-auto md:grid md:grid-cols-3'>
          {data.map((image, index) => (
            <picture key={index} className='w-full'>
              <img src={image.image} alt={image.name} className={`h-64 w-full min-w-80 rounded-lg object-cover`} />
            </picture>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OurSpace
