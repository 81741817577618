import React from 'react'
import bg from 'assets/images/banner-large.png'

function Banner() {
  return (
    <section
      id='inicio'
      className='bg-gray relative flex min-h-svh items-center justify-center overflow-hidden transition-opacity duration-1000'
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className='flex justify-center items-center w-11/12 md:w-10/12'>
        <svg className='mt-8' width="800" height="169" viewBox="0 0 800 169" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M331.107 165.594C331.115 165.586 317.29 152.526 296.79 130.5C286.561 119.392 274.599 106.415 262.188 90.8092C259.115 86.7087 257.32 84.681 256.476 76.5251C255.548 66.8369 261.956 56.8186 265.889 53.1462C269.889 48.535 271.997 46.267 271.077 40.7997C266.846 19.674 165.933 23.4741 196.857 22.1523C208.528 21.6867 220.782 21.3488 233.634 21.3337C240.117 21.3563 246.576 21.3938 253.799 21.8369C262.569 22.5278 269.313 23.9622 277.851 27.7022C281.896 29.6323 286.345 32.4936 289.223 36.9546C292.184 41.393 292.304 46.8302 290.861 50.7204C286.943 59.6874 282.023 62.8191 279.122 66.2361C275.623 70.269 275.122 70.9599 273.866 73.6185C264.423 90.1858 331.115 165.594 331.107 165.594Z" fill="white" />
          <path d="M4.7076 37.8109C13.8515 33.9958 24.431 32.7867 34.2253 32.7191C57.7917 32.5539 80.1391 41.9188 97.5073 57.7575C111.122 70.1715 121.163 85.9652 129.276 102.412C136.019 116.088 144.588 130.004 157.036 139.174C164.961 145.009 174.322 147.487 183.975 145.354C193.604 143.229 201.948 136.921 208.961 130.244C225.305 114.691 235.855 94.8195 246.659 75.3159C247.668 73.4909 242.375 68.8422 242.076 69.383C234.696 82.7059 227.623 95.961 218.464 108.187C207.317 123.065 190.464 141.832 169.986 140.022C158.068 138.971 148.872 130.102 142.345 120.722C136.782 112.723 132.902 103.794 128.244 95.2701C123.735 87.0241 118.599 79.1311 112.894 71.666C96.64 50.4052 74.502 33.4851 47.9375 28.2957C32.6926 25.3217 14.7039 25.7873 0.124418 31.8704C-0.720442 32.2309 2.95059 38.5468 4.7076 37.8109Z" fill="white" />
          <path d="M362.951 135.387C351.13 112.106 339.422 88.9376 327.833 65.882H332.873C337.852 75.7801 342.899 85.7609 347.998 95.8318C353.097 105.903 358.084 116.004 362.951 126.127C367.706 115.996 372.626 105.933 377.732 95.9144C382.831 85.9036 387.93 75.8927 393.03 65.8745H397.897C391.983 77.5149 386.158 89.1028 380.424 100.631C374.682 112.159 368.858 123.747 362.951 135.387Z" fill="white" />
          <path d="M417.194 43.3524C417.194 42.0757 417.658 40.9717 418.585 40.033C419.512 39.1017 420.611 38.6361 421.889 38.6361C423.048 38.6361 424.088 39.1017 425.022 40.033C425.949 40.9642 426.413 42.0682 426.413 43.3524C426.413 44.6366 425.949 45.7105 425.022 46.5817C424.095 47.4529 423.048 47.8884 421.889 47.8884C420.611 47.8884 419.512 47.4529 418.585 46.5817C417.658 45.7105 417.194 44.6291 417.194 43.3524ZM424.147 131.37H419.28V65.8824H424.147V131.37Z" fill="white" />
          <path d="M455.968 131.37H451.101V0.387817H455.968V131.37Z" fill="white" />
          <path d="M477.874 98.5362C477.874 93.88 478.742 89.4866 480.484 85.3486C482.226 81.2181 484.626 77.6058 487.699 74.5192C490.772 71.4326 494.36 68.9919 498.48 67.1819C502.592 65.3795 507.026 64.4708 511.781 64.4708C515.258 64.4708 518.562 64.9665 521.695 65.9578C524.82 66.9491 527.691 68.316 530.301 70.0583C532.91 71.8081 535.168 73.8433 537.082 76.1714C538.996 78.4995 540.469 80.9477 541.516 83.5086C541.516 80.6023 541.575 77.6884 541.687 74.7746C541.8 71.8682 541.979 68.8942 542.211 65.8677H545.859V131.355H541.516V122.636V113.556C539.542 118.798 535.923 123.252 530.652 126.917C525.373 130.581 519.086 132.474 511.788 132.587C507.033 132.587 502.6 131.685 498.487 129.883C494.368 128.081 490.779 125.662 487.706 122.636C484.633 119.609 482.233 116.027 480.491 111.896C478.742 107.766 477.874 103.313 477.874 98.5362ZM482.742 98.5362C482.742 102.614 483.497 106.422 485 109.974C486.502 113.526 488.566 116.613 491.168 119.226C493.777 121.847 496.843 123.913 500.379 125.43C503.916 126.947 507.706 127.698 511.766 127.698C515.818 127.698 519.646 126.939 523.243 125.43C526.831 123.92 529.964 121.847 532.633 119.226C535.295 116.605 537.388 113.526 538.891 109.974C540.394 106.422 541.149 102.614 541.149 98.5362C541.149 94.4657 540.394 90.6507 538.891 87.0984C537.381 83.5537 535.295 80.437 532.633 77.756C529.964 75.0825 526.839 72.9797 523.243 71.4702C519.646 69.9606 515.826 69.2021 511.766 69.2021C507.706 69.2021 503.916 69.9606 500.379 71.4702C496.843 72.9872 493.77 75.0825 491.168 77.756C488.559 80.437 486.502 83.5537 485 87.0984C483.497 90.6507 482.742 94.4657 482.742 98.5362Z" fill="white" />
          <path d="M613.963 69.3747C610.247 69.4874 606.749 70.1858 603.444 71.47C600.14 72.7543 597.246 74.4966 594.749 76.7121C592.252 78.9276 590.285 81.5486 588.834 84.5751C587.384 87.6016 586.659 90.861 586.659 94.3531V131.377H581.791V65.89H586.307V73.5728C586.188 75.2025 586.135 76.7722 586.135 78.2892C586.135 79.8062 586.135 81.3758 586.135 83.0055C588.685 77.3054 592.513 72.8518 597.612 69.645C602.711 66.4458 608.221 64.7861 614.128 64.6659L613.963 69.3747Z" fill="white" />
          <path d="M690.114 87.5333C680.723 91.3785 671.332 95.1635 661.949 98.8885C652.559 102.613 643.168 106.399 633.785 110.244C635.983 115.486 639.437 119.699 644.132 122.906C648.828 126.105 654.241 127.704 660.387 127.704C664.327 127.704 667.975 127.006 671.34 125.609C674.704 124.212 677.717 122.29 680.379 119.849C680.843 120.435 681.306 121.013 681.77 121.599C682.233 122.185 682.757 122.763 683.332 123.349C680.319 126.262 676.873 128.531 672.985 130.16C669.104 131.79 664.902 132.601 660.379 132.601C655.624 132.601 651.19 131.7 647.078 129.897C642.966 128.095 639.4 125.677 636.387 122.65C633.373 119.624 630.966 116.041 629.172 111.911C627.37 107.78 626.473 103.327 626.473 98.5505C626.473 93.8943 627.37 89.4709 629.172 85.2803C630.966 81.0898 633.373 77.4774 636.387 74.4512C639.4 71.4247 642.966 69.0139 647.078 67.204C651.19 65.4016 655.624 64.4929 660.379 64.4929C667.332 64.4929 673.448 66.6483 678.719 70.9515C683.998 75.2548 687.796 80.7818 690.114 87.5333ZM660.379 69.1942C656.439 69.1942 652.753 69.9227 649.336 71.3796C645.919 72.8365 642.906 74.8117 640.297 77.3197C637.687 79.8281 635.602 82.7645 634.039 86.144C632.476 89.5235 631.631 93.1283 631.519 96.9734C631.4 98.6031 631.43 100.113 631.609 101.509C631.781 102.906 631.983 104.303 632.215 105.7C640.91 102.208 649.516 98.7458 658.031 95.3062C666.555 91.8741 675.16 88.4045 683.848 84.9124C681.643 80.2561 678.487 76.4711 674.375 73.5575C670.263 70.6511 665.598 69.1942 660.379 69.1942Z" fill="white" />
          <path d="M701.761 98.5362C701.761 93.88 702.629 89.4866 704.371 85.3486C706.113 81.2181 708.513 77.6058 711.586 74.5192C714.659 71.4326 718.247 68.9919 722.367 67.1819C726.479 65.3795 730.913 64.4708 735.668 64.4708C739.145 64.4708 742.449 64.9665 745.582 65.9578C748.71 66.9491 751.581 68.316 754.191 70.0583C756.8 71.8081 759.058 73.8433 760.972 76.1714C762.886 78.4995 764.359 80.9477 765.406 83.5086C765.406 80.6023 765.465 77.6884 765.578 74.7746C765.69 71.8682 765.869 68.8942 766.101 65.8677H769.757V131.355H765.413V122.636V113.556C763.439 118.798 759.821 123.252 754.55 126.917C749.271 130.581 742.988 132.474 735.683 132.587C730.928 132.587 726.494 131.685 722.382 129.883C718.262 128.081 714.674 125.662 711.601 122.636C708.528 119.609 706.128 116.027 704.386 111.896C702.629 107.766 701.761 103.313 701.761 98.5362ZM706.629 98.5362C706.629 102.614 707.384 106.422 708.887 109.974C710.389 113.526 712.453 116.613 715.055 119.226C717.664 121.847 720.73 123.913 724.266 125.43C727.803 126.947 731.593 127.698 735.653 127.698C739.713 127.698 743.533 126.939 747.13 125.43C750.729 123.92 753.854 121.847 756.523 119.226C759.185 116.605 761.278 113.526 762.781 109.974C764.284 106.422 765.039 102.614 765.039 98.5362C765.039 94.4657 764.284 90.6507 762.781 87.0984C761.271 83.5537 759.185 80.437 756.523 77.756C753.854 75.0825 750.729 72.9797 747.13 71.4702C743.533 69.9606 739.713 69.2021 735.653 69.2021C731.593 69.2021 727.803 69.9606 724.266 71.4702C720.73 72.9872 717.664 75.0825 715.055 77.756C712.446 80.437 710.389 83.5537 708.887 87.0984C707.384 90.6507 706.629 94.4657 706.629 98.5362Z" fill="white" />
          <path d="M798.953 131.37H794.086V0.387817H798.953V131.37Z" fill="white" />
          <path d="M581.972 158.009V157.821C581.972 154.209 584.469 151.678 587.856 151.678C591.25 151.678 593.747 154.186 593.747 157.799V157.986C593.747 161.621 591.25 164.129 587.833 164.129C584.417 164.129 581.972 161.531 581.972 158.009ZM591.774 158.009V157.851C591.774 155.065 590.241 153.27 587.856 153.27C585.448 153.27 583.938 155.065 583.938 157.829V158.016C583.938 160.757 585.426 162.552 587.856 162.552C590.263 162.545 591.774 160.727 591.774 158.009Z" fill="white" />
          <path d="M595.803 158.143V157.955C595.803 154.275 598.091 151.677 601.396 151.677C603.392 151.677 604.626 152.646 605.314 153.817V146.135H607.213V163.918H605.314V161.846C604.626 163.017 602.951 164.121 601.209 164.121C598.076 164.129 595.803 161.898 595.803 158.143ZM605.381 157.985V157.798C605.381 154.711 603.916 153.262 601.575 153.262C599.19 153.262 597.77 154.944 597.77 157.865V158.053C597.77 161.087 599.4 162.537 601.411 162.537C603.639 162.544 605.381 161.072 605.381 157.985Z" fill="white" />
          <path d="M610.166 158.009V157.821C610.166 154.209 612.663 151.678 616.05 151.678C619.445 151.678 621.942 154.186 621.942 157.799V157.986C621.942 161.621 619.445 164.129 616.028 164.129C612.611 164.129 610.166 161.531 610.166 158.009ZM619.975 158.009V157.851C619.975 155.065 618.443 153.27 616.058 153.27C613.65 153.27 612.14 155.065 612.14 157.829V158.016C612.14 160.757 613.628 162.552 616.058 162.552C618.458 162.545 619.975 160.727 619.975 158.009Z" fill="white" />
          <path d="M624.895 151.888H626.794V153.796C627.347 152.669 628.671 151.678 630.645 151.678C633.119 151.678 634.861 153.037 634.861 156.529V163.912H632.962V156.387C632.962 154.269 632.065 153.323 630.144 153.323C628.379 153.323 626.802 154.449 626.802 156.612V163.904H624.903V151.888H624.895Z" fill="white" />
          <path d="M639.003 160.907V153.495H637.284V151.887H639.003V149.146H640.902V151.887H643.699V153.495H640.902V160.719C640.902 161.891 641.433 162.469 642.39 162.469C642.988 162.469 643.444 162.379 643.856 162.214V163.828C643.467 163.963 643.011 164.084 642.226 164.084C640.058 164.084 639.003 162.792 639.003 160.907Z" fill="white" />
          <path d="M645.83 158.009V157.821C645.83 154.209 648.327 151.678 651.714 151.678C655.108 151.678 657.605 154.186 657.605 157.799V157.986C657.605 161.621 655.108 164.129 651.691 164.129C648.274 164.129 645.83 161.531 645.83 158.009ZM655.639 158.009V157.851C655.639 155.065 654.106 153.27 651.721 153.27C649.314 153.27 647.803 155.065 647.803 157.829V158.016C647.803 160.757 649.291 162.552 651.721 162.552C654.121 162.545 655.639 160.727 655.639 158.009Z" fill="white" />
          <path d="M660.603 146.135H662.525V163.918H660.603V146.135Z" fill="white" />
          <path d="M665.53 158.009V157.821C665.53 154.209 668.027 151.678 671.414 151.678C674.808 151.678 677.305 154.186 677.305 157.799V157.986C677.305 161.621 674.808 164.129 671.391 164.129C667.975 164.129 665.53 161.531 665.53 158.009ZM675.332 158.009V157.851C675.332 155.065 673.799 153.27 671.414 153.27C669.006 153.27 667.496 155.065 667.496 157.829V158.016C667.496 160.757 668.984 162.552 671.414 162.552C673.821 162.545 675.332 160.727 675.332 158.009Z" fill="white" />
          <path d="M679.616 164.61H681.56C681.837 165.992 682.981 166.705 685.022 166.705C687.385 166.705 688.872 165.579 688.872 163.003V161.253C688.185 162.425 686.562 163.529 684.82 163.529C681.68 163.529 679.37 161.133 679.37 157.821V157.663C679.37 154.396 681.658 151.678 684.962 151.678C686.958 151.678 688.192 152.647 688.88 153.818V151.888H690.779V163.116C690.757 166.615 688.394 168.313 685.052 168.313C681.493 168.32 679.938 166.638 679.616 164.61ZM688.94 157.686V157.528C688.94 154.84 687.474 153.27 685.134 153.27C682.749 153.27 681.329 155.042 681.329 157.573V157.753C681.329 160.374 683.003 161.944 685.014 161.944C687.243 161.944 688.94 160.382 688.94 157.686Z" fill="white" />
          <path d="M694.323 148.118C694.323 147.427 694.899 146.856 695.579 146.856C696.267 146.856 696.843 147.435 696.843 148.118C696.843 148.809 696.267 149.387 695.579 149.387C694.891 149.38 694.323 148.809 694.323 148.118ZM694.667 151.888H696.566V163.919H694.667V151.888Z" fill="white" />
          <path d="M699.474 160.585C699.474 157.798 702.405 156.86 705.478 156.86H707.198V155.989C707.198 154.081 706.465 153.27 704.633 153.27C702.981 153.27 702.069 153.961 701.882 155.433H699.983C700.237 152.692 702.413 151.685 704.723 151.685C707.011 151.685 709.097 152.632 709.097 155.989V163.927H707.198V162.41C706.301 163.536 705.231 164.137 703.467 164.137C701.239 164.129 699.474 163.048 699.474 160.585ZM707.198 159.714V158.219H705.545C703.138 158.219 701.373 158.82 701.373 160.592C701.373 161.831 701.972 162.62 703.639 162.62C705.658 162.612 707.198 161.576 707.198 159.714Z" fill="white" />
          <path d="M718.001 158.031V157.844C718.001 154.209 720.312 151.678 723.616 151.678C726.39 151.678 729 153.36 729 157.663V158.287H719.975C720.065 161.028 721.373 162.545 723.826 162.545C725.68 162.545 726.757 161.854 727.011 160.45H728.91C728.499 162.868 726.502 164.129 723.803 164.129C720.409 164.129 718.001 161.734 718.001 158.031ZM727.056 156.747C726.869 154.261 725.59 153.27 723.616 153.27C721.672 153.27 720.364 154.607 720.043 156.747H727.056Z" fill="white" />
          <path d="M731.041 160.217H732.895C733.03 161.644 733.785 162.537 735.714 162.537C737.523 162.537 738.323 161.914 738.323 160.63C738.323 159.368 737.381 158.948 735.527 158.602C732.409 158.024 731.355 157.153 731.355 155.057C731.355 152.827 733.53 151.678 735.407 151.678C737.56 151.678 739.415 152.549 739.736 155.155H737.904C737.628 153.841 736.895 153.27 735.385 153.27C734.009 153.27 733.119 153.961 733.119 154.997C733.119 156.079 733.717 156.514 735.916 156.905C738.39 157.363 740.132 157.919 740.132 160.472C740.132 162.657 738.644 164.129 735.736 164.129C732.873 164.129 731.153 162.68 731.041 160.217Z" fill="white" />
          <path d="M743.43 160.907V153.495H741.71V151.887H743.43V149.146H745.329V151.887H748.127V153.495H745.329V160.719C745.329 161.891 745.86 162.469 746.817 162.469C747.415 162.469 747.873 162.379 748.284 162.214V163.828C747.895 163.963 747.437 164.084 746.652 164.084C744.484 164.084 743.43 162.792 743.43 160.907Z" fill="white" />
          <path d="M750.258 158.031V157.844C750.258 154.209 752.568 151.678 755.873 151.678C758.647 151.678 761.256 153.36 761.256 157.663V158.287H752.232C752.321 161.028 753.63 162.545 756.082 162.545C757.936 162.545 759.013 161.854 759.267 160.449H761.166C760.755 162.868 758.759 164.129 756.06 164.129C752.665 164.129 750.258 161.734 750.258 158.031ZM759.305 156.747C759.118 154.261 757.839 153.27 755.865 153.27C753.921 153.27 752.613 154.607 752.292 156.747H759.305ZM756.441 146.436H758.826L756.441 149.815H755.021L756.441 146.436Z" fill="white" />
          <path d="M764.576 160.907V153.495H762.856V151.887H764.576V149.146H766.475V151.887H769.271V153.495H766.475V160.719C766.475 161.891 767.006 162.469 767.963 162.469C768.561 162.469 769.017 162.379 769.428 162.214V163.828C769.039 163.963 768.583 164.084 767.798 164.084C765.63 164.084 764.576 162.792 764.576 160.907Z" fill="white" />
          <path d="M771.993 148.118C771.993 147.427 772.568 146.856 773.249 146.856C773.936 146.856 774.512 147.435 774.512 148.118C774.512 148.809 773.936 149.387 773.249 149.387C772.568 149.38 771.993 148.809 771.993 148.118ZM772.336 151.888H774.236V163.919H772.336V151.888Z" fill="white" />
          <path d="M777.241 158.031V157.844C777.241 154.164 779.85 151.678 783.058 151.678C785.555 151.678 787.888 152.827 788.262 155.891H786.385C786.086 153.983 784.71 153.27 783.065 153.27C780.89 153.27 779.215 155.02 779.215 157.851V158.039C779.215 160.983 780.815 162.545 783.133 162.545C784.83 162.545 786.363 161.554 786.572 159.601H788.337C788.082 162.199 786.004 164.137 783.11 164.137C779.761 164.129 777.241 161.764 777.241 158.031Z" fill="white" />
          <path d="M790.206 160.585C790.206 157.798 793.136 156.86 796.209 156.86H797.929V155.989C797.929 154.081 797.196 153.27 795.364 153.27C793.712 153.27 792.8 153.961 792.613 155.433H790.714C790.968 152.692 793.144 151.685 795.454 151.685C797.742 151.685 799.828 152.632 799.828 155.989V163.927H797.929V162.41C797.032 163.536 795.955 164.137 794.198 164.137C791.97 164.129 790.206 163.048 790.206 160.585ZM797.921 159.714V158.219H796.269C793.862 158.219 792.097 158.82 792.097 160.592C792.097 161.831 792.695 162.62 794.363 162.62C796.389 162.612 797.921 161.576 797.921 159.714Z" fill="white" />
        </svg>
      </div>
    </section >
  )
}

export default Banner