import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/icons/logo.svg'
import menu from 'assets/icons/menu.svg'
import Navigation from 'components/Navigation'

function Header() {
  const [showNavigation, setShowNavigation] = useState(false)
  const [isHeaderVisible, setIsHeaderVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (Math.abs(currentScrollY - lastScrollY) > 200) {
      setIsHeaderVisible(currentScrollY < lastScrollY)
      setLastScrollY(currentScrollY)
    }
  }

  window.addEventListener('scroll', handleScroll)

  const handleClick = () => {
    setShowNavigation(!showNavigation)
  }

  const scrollToSection = (id) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <header
        className={`animate-slide-down bg-lightGray fixed z-30 flex w-full items-center justify-between bg-white p-4 transition-transform duration-300 md:justify-around ${isHeaderVisible ? 'translate-y-0' : '-translate-y-full'}`}
      >
        <div className='w-1/2 md:w-2/12'>
          <Link to={'/#'}>
            <picture>
              <img src={logo} alt='Vila Real Odontologia' className='w-full' />
            </picture>
          </Link>
        </div>

        <ul className={`hidden items-center justify-end gap-12 text-xs md:flex md:text-base lg:text-lg xl:text-xl`}>
          <li>
            <button onClick={() => scrollToSection('quem-somos')} className='hover:text-gray'>
              Sobre
            </button>
          </li>
          <li>
            <button onClick={() => scrollToSection('tratamentos')} className='hover:text-gray'>
              Tratamentos
            </button>
          </li>
          <li>
            <button onClick={() => scrollToSection('clinica')} className='hover:text-gray'>
              Clínica
            </button>
          </li>
          <li>
            <button onClick={() => scrollToSection('contato')} className='hover:text-gray'>
              Contato
            </button>
          </li>
          <li>
            <a
              href='https://api.whatsapp.com/send?phone=5543999512211&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20agendar%20uma%20consulta.'
              target='_blank'
              className='rounded-full border-2 border-gray p-4 px-8 font-medium text-gray transition-all duration-500 hover:opacity-75'
              rel='noreferrer'
            >
              Agendar Consulta
            </a>
          </li>
        </ul>

        <button className='h-10 w-10 md:hidden' onClick={handleClick}>
          <picture>
            <img src={menu} alt='Menu hamburger' className='w-full' />
          </picture>
        </button>
      </header>

      <Navigation isOpen={showNavigation} onClose={handleClick} />
    </>
  )
}

export default Header
