import React from 'react'
import useScrollVisibility from 'hooks/useScrollVisibility'
import whatsappIcon from 'assets/icons/whatsapp.svg'

function Contact() {
  const [isVisible, sectionRef] = useScrollVisibility('contact-section')

  return (
    <section ref={sectionRef} id='contato' className={`bg-sand flex w-full flex-col items-center justify-center py-8`}>
      <div
        className={`flex w-11/12 flex-col items-center justify-center gap-4 md:flex-row ${isVisible ? 'animate-scaleUp' : 'opacity-0'}`}
      >
        <div className='flex flex-col items-center justify-center gap-4'>
          <img src={whatsappIcon} alt='Whatsapp' className='h-14 w-14 md:h-20 md:w-20' />

          <figcaption className='text-2xl font-semibold md:text-5xl'>Atendimento Personalizado</figcaption>

          <p className='text-center text-xl md:text-2xl'>
            Garanta um atendimento humanizado e personalizado dedicado a você!
          </p>

          <button className='rounded-full border-2 border-gray p-4 px-8 text-xl font-medium text-gray transition-all duration-500 hover:opacity-75'>
            <a
              href='https://api.whatsapp.com/send?phone=5543999512211&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20agendar%20uma%20consulta.'
              target='_blank'
            >
              Agendar Consulta
            </a>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Contact
