import React from 'react'
import Header from 'components/Header'
import Banner from './components/Banner'
import About from './components/About'
import Footer from 'components/Footer'
import Whatsapp from 'components/Whatsapp'
import Contact from './components/Contact'
import DentalCare from './components/DentalCare'
import Ourspace from './components/OurSpace'

import aline from 'assets/images/dra-aline.jpg'
import luis from 'assets/images/dr-luis.jpg'

import image1 from 'assets/images/tratamento (1).jpg'
import image2 from 'assets/images/tratamento (2).jpg'
import image3 from 'assets/images/tratamento (3).jpg'
import image4 from 'assets/images/tratamento (4).jpg'
import image5 from 'assets/images/tratamento (5).jpg'
import image6 from 'assets/images/tratamento (6).jpg'
import image7 from 'assets/images/tratamento (7).jpg'
import image8 from 'assets/images/tratamento (8).jpg'
import image9 from 'assets/images/tratamento (9).jpg'

import space1 from 'assets/images/space (1).png'
import space2 from 'assets/images/space (2).png'
import space3 from 'assets/images/space (3).png'
import space4 from 'assets/images/space (4).png'
import space5 from 'assets/images/space (5).png'
import space6 from 'assets/images/space (6).png'

function Home() {
  const Aline = {
    name: 'Dra. Aline Vila Real',
    cro: 'CRO-PR 17768',
    paragraphs: [
      'Sou Aline Vila Real, dentista apaixonada por transformar sorrisos e proporcionar uma experiência de cuidado única para cada paciente. Minha formação inclui especializações em Endodontia e Implantodontia, com estudos na USP, que me permitem oferecer o melhor para quem confia no meu trabalho.',
      'Cada atendimento é mais do que um tratamento; é uma oportunidade de ouvir, acolher e cuidar. Quero que cada paciente sinta que seu sorriso é especial e tratado com o mesmo carinho e dedicação que eu buscaria para mim mesma. Esse é o legado que desejo deixar – um atendimento feito com respeito e um toque de humanidade.',
    ],
    image: aline,
    link: 'sobre/aline',
  }

  const Luis = {
    name: 'Dr. Luis Vila Real',
    cro: 'CRO-PR 23936',
    paragraphs: [
      'Sou Luis Vila Real, dentista comprometido em oferecer um atendimento de excelência que inspire confiança e tranquilidade aos meus pacientes. Desde o início da minha carreira, busco ser uma referência, cuidando de cada detalhe para que cada pessoa que atendo se sinta valorizada e bem cuidada.',
      'Com especializações no Brasil e no exterior, estou sempre em busca do que há de mais avançado em odontologia. Para mim, a saúde bucal vai além da técnica; envolve respeito, acolhimento e um olhar atento para cada necessidade. Minha meta é que a clínica seja lembrada pela qualidade e pelo vínculo de confiança que construímos com cada sorriso.',
    ],
    image: luis,
    link: 'sobre/luis',
  }

  const otherTreatments = [
    { name: 'restauracoes-dentarias', image: image1 },
    { name: 'tratamento-bruxismo', image: image2 },
    { name: 'tratamento-endodontico', image: image3 },
    { name: 'clareamento-dental', image: image4 },
    { name: 'aparelho-ronco', image: image5 },
    { name: 'extracoes-dentarias', image: image6 },
  ]

  const featuredTreatments = [
    { name: 'facetas-porcelana', image: image7 },
    { name: 'aparelho-ortodontico', image: image8 },
    { name: 'implantes-dentarios', image: image9 },
  ]

  const clinicPhotos = [
    { name: 'aqui vai a mensagem do alt', image: space6 },
    { name: 'aqui vai a mensagem do alt', image: space1 },
    { name: 'aqui vai a mensagem do alt', image: space2 },
    { name: 'aqui vai a mensagem do alt', image: space3 },
    { name: 'aqui vai a mensagem do alt', image: space4 },
    { name: 'aqui vai a mensagem do alt', image: space5 },
  ]

  return (
    <main>
      <Header />
      <Banner />
      <About data={Aline} reverse={true} />
      <About data={Luis} reverse={false} />
      <DentalCare featuredTreatments={featuredTreatments} otherTreatments={otherTreatments} />
      <Ourspace data={clinicPhotos} />
      <Contact />
      <Footer />
      <Whatsapp message={'Olá, vim pelo site e gostaria de agendar uma consulta.'} />
    </main>
  )
}

export default Home
