import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Header from './components/Header'
import Footer from 'components/Footer'

import image1 from 'assets/images/tratamento (1).jpg'
import image2 from 'assets/images/tratamento (2).jpg'
import image3 from 'assets/images/tratamento (3).jpg'
import image4 from 'assets/images/tratamento (4).jpg'
import image5 from 'assets/images/tratamento (5).jpg'
import image6 from 'assets/images/tratamento (6).jpg'
import image7 from 'assets/images/tratamento (7) 1080x1080.jpg'
import image8 from 'assets/images/tratamento (8) 1080x1080.jpg'
import image9 from 'assets/images/tratamento (9) 1080x1080.jpg'

const tratamentos = {
  'restauracoes-dentarias': {
    id: 'restauracoes-dentarias',
    name: 'Restaurações Dentárias',
    description:
      'As Restaurações Dentárias envolvem a reparação de dentes danificados por cáries, traumas ou desgaste. Utilizamos materiais estéticos e duráveis, como resinas compostas e porcelanas, para devolver a função e a aparência natural dos dentes. O processo de restauração é minucioso e personalizado, garantindo a satisfação e o bem-estar do paciente.',
    image: image1,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Restaurações Dentárias.',
  },
  'tratamento-bruxismo': {
    id: 'tratamento-bruxismo',
    name: 'Tratamento de Bruxismo',
    description:
      'O Tratamento de Bruxismo é um processo especializado que visa reduzir o ranger noturno dos dentes. Utilizando técnicas modernas e personalizadas, buscamos aliviar os sintomas do bruxismo, prevenir o desgaste dental e melhorar a qualidade de vida do paciente.',
    image: image2,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Tratamento de Bruxismo.',
  },
  'tratamento-endodontico': {
    id: 'tratamento-endodontico',
    name: 'Tratamento Endodôntico',
    description:
      'O Tratamento Endodôntico, também conhecido como tratamento de canal, envolve a remoção da polpa infectada do dente, limpeza e desinfecção dos canais radiculares e, finalmente, o preenchimento dos mesmos.',
    image: image3,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Tratamento Endodôntico.',
  },
  'clareamento-dental': {
    id: 'clareamento-dental',
    name: 'Clareamento Dental',
    description:
      'O Clareamento Dental é um procedimento estético que visa clarear a cor dos dentes, removendo manchas e descolorações causadas por alimentos, bebidas e outros fatores.',
    image: image4,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Clareamento Dental.',
  },
  'aparelho-ronco': {
    id: 'aparelho-ronco',
    name: 'Aparelho para Ronco',
    description:
      'O Aparelho para Ronco é um dispositivo intraoral utilizado para tratar o ronco e a apneia do sono. Ele ajusta a posição da mandíbula e da língua para manter as vias aéreas abertas durante o sono.',
    image: image5,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Aparelho para Ronco.',
  },
  'extracoes-dentarias': {
    id: 'extracoes-dentarias',
    name: 'Extrações Dentárias',
    description:
      'As Extrações Dentárias são procedimentos realizados para remover dentes comprometidos por cáries extensas, doenças periodontais, ou que estão causando problemas ortodônticos.',
    image: image6,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Extrações Dentárias.',
  },
  'facetas-porcelana': {
    id: 'facetas-porcelana',
    name: 'Facetas de Porcelana',
    description:
      'As Facetas de Porcelana são finas lâminas de porcelana coladas à superfície frontal dos dentes para corrigir imperfeições estéticas.',
    image: image7,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Facetas de Porcelana.',
  },
  'aparelho-ortodontico': {
    id: 'aparelho-ortodontico',
    name: 'Aparelho Ortodôntico',
    description:
      'O Aparelho Ortodôntico é um dispositivo utilizado para corrigir a posição dos dentes e dos ossos maxilares.',
    image: image8,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Aparelho Ortodôntico.',
  },
  'implantes-dentarios': {
    id: 'implantes-dentarios',
    name: 'Implantes Dentários',
    description:
      'Os Implantes Dentários são raízes artificiais de titânio que são inseridas no osso maxilar para substituir dentes perdidos.',
    image: image9,
    whatsappMessage: 'Olá, gostaria de agendar uma consulta para Implantes Dentários.',
  },
}

function OurTreatments() {
  const { name } = useParams()
  const treatment = tratamentos[name]

  useEffect(() => {
    if (treatment) {
      document.title = `Vila Real Odontologia - ${treatment.name}`
      window.scrollTo(0, 0)
    }
  }, [treatment])

  if (!treatment) {
    return <main>Tratamento não encontrado.</main>
  }

  const whatsappLink = `https://api.whatsapp.com/send?phone=5543999512211&text=${encodeURIComponent(treatment.whatsappMessage)}`

  return (
    <>
      <Header />
      <section className='flex w-full items-center justify-center'>
        <div className='m-4 flex w-11/12 flex-col md:m-20 md:w-9/12 lg:m-24'>
          <div id={treatment.id} className='mb-20 grid grid-cols-1 items-center justify-center gap-8 md:grid-cols-2'>
            <picture>
              <img src={treatment.image} alt={treatment.name} className='w-10/12 rounded-xl' />
            </picture>

            <div className='flex w-full flex-col gap-4'>
              <h1 className='text-4xl font-semibold'>{treatment.name}</h1>
              <p className='text-justify text-xl'>{treatment.description}</p>
              <button className='border-gray-600 w-2/3 rounded-full border-2 p-4 hover:opacity-75'>
                <a className='text-gray-600 text-lg md:text-xl' href={whatsappLink} target='_blank' rel='noreferrer'>
                  Agendar Consulta
                </a>
              </button>
            </div>
          </div>

          <div className='flex flex-col items-center justify-center gap-8 lg:flex-row'>
            <header className='flex flex-col items-center justify-center gap-4 text-center'>
              <h2 className='text-gray-900 text-4xl font-semibold md:text-5xl'>
                Outros <br />
                <span className='font-bold'>Tratamentos</span>
              </h2>
            </header>

            <ul className='grid grid-cols-2 gap-4 md:grid-cols-4'>
              {Object.keys(tratamentos)
                .filter((key) => key !== name)
                .map((key) => {
                  const otherTreatment = tratamentos[key]
                  return (
                    <li key={otherTreatment.id} className='w-full'>
                      <Link to={`/tratamentos/${otherTreatment.id}`}>
                        <picture>
                          <img src={otherTreatment.image} alt={otherTreatment.name} className='w-full rounded-xl' />
                        </picture>
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default OurTreatments
