import React from 'react'
import { Link } from 'react-router-dom'
import useScrollVisibility from 'hooks/useScrollVisibility'

function DentalCare({ featuredTreatments, otherTreatments }) {
  const [isVisible, sectionRef] = useScrollVisibility()

  return (
    <section ref={sectionRef} id='tratamentos' className='bg-lightGray flex w-full items-center justify-center'>
      <div className='my-12 flex w-11/12 flex-col items-center justify-center gap-8 md:w-9/12'>
        <div className='flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-12'>
          <header
            className={`flex w-full flex-col items-center justify-center gap-4 text-center md:w-1/4 md:text-left ${isVisible ? 'animate-slideInLeft' : 'opacity-0'}`}
          >
            <h2 className='text-gray-900 text-4xl font-semibold md:text-5xl'>
              Tratamentos <br /> <span className='font-bold'>Odontológicos</span>
            </h2>
          </header>

          <ul className='m-4 grid grid-cols-2 gap-4 md:grid-cols-3'>
            {otherTreatments.map((treatment, index) => (
              <OtherTreatmentCard key={index} data={treatment} isVisible={isVisible} />
            ))}
          </ul>
        </div>

        <div className='flex w-full overflow-hidden md:w-9/12'>
          <ul className='flex w-full gap-4 overflow-x-auto px-4'>
            {featuredTreatments.map((treatment, index) => (
              <FeaturedTreatmentCard key={index} data={treatment} isVisible={isVisible} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

function OtherTreatmentCard({ data, isVisible }) {
  return (
    <li className={`relative ${isVisible ? 'animate-scaleUp' : 'opacity-0'}`}>
      <Link to={`/tratamentos/${data.name}`} target='_blank'>
        <picture>
          <img src={data.image} alt={data.name} className='w-full rounded-lg' />
        </picture>
      </Link>
    </li>
  )
}

function FeaturedTreatmentCard({ data, isVisible }) {
  return (
    <li className={`relative min-w-64 ${isVisible ? 'animate-scaleUp' : 'opacity-0'}`}>
      <Link to={`/tratamentos/${data.name}`} target='_blank'>
        <picture>
          <img src={data.image} alt={data.name} className='w-full rounded-lg' />
        </picture>
        <button className='text-gray-800 absolute bottom-4 left-1/2 -translate-x-1/2 transform rounded-lg bg-white bg-opacity-85 px-4 py-2 font-semibold shadow-md transition duration-300 ease-in-out hover:opacity-75'>
          Saiba Mais
        </button>
      </Link>
    </li>
  )
}

export default DentalCare
